/** 登录 */
<template>
  <el-form :model="form" :rules="$rules.common" ref="form">
    <el-form-item prop="phone">
      <el-input v-model="form.phone" placeholder="请输入手机号码" prefix-icon="el-icon-mobile-phone"></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input :type="isPassType?'password':'text'" v-model="form.password" placeholder="请输入登录密码"
        prefix-icon="el-icon-lock">
        <el-image slot="suffix" class="pass-eye"
          :src="require(isPassType? '@/assets/images/eye-hide.png':'@/assets/images/eye-show.png')"
          @click="isPassType=!isPassType">
        </el-image>
      </el-input>
      <el-button class="back-pass" type="text" @click="$store.dispatch('setPassBack', true)">忘记密码？</el-button>
    </el-form-item>
    <el-form-item class="form-btns login-btn">
      <el-button class="theme-back" type="primary" :loading="isLoading" @click="login('form')">登录</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { login } from '@/api/account'
export default {
  name: 'Login',
  inject: ['reload'],//注入App里的reload方法
  data () {
    return {
      isLoading: false, // 登录加载状态
      isPassType: true,// 密码切换显示
      form: {
        phone: '',
        password: '',
      },
    };
  },
  mounted () { },
  methods: {
    // 登录
    login (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            let { data, status, msg } = await login(this.form)
            if (status == 200) {
              this.$message.success('登录成功')
              this.$store.dispatch('setUserToken', data.token)
              this.$store.dispatch('setUserInfo', data.userInfo)
              this.reload() // 登录成功刷新数据
            } else {
              this.$message.error('手机号或密码错误')
            }
          } catch (error) {
            this.$message.error(error.errors.phone[0]);
          }
          this.isLoading = false
        } else {
          return false;
        }
      });
    },
  },
}
</script>