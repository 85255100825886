import axios from 'axios'
import store from '@/store'
import { Loading, Message } from 'element-ui'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true, // 跨域请求时是否需要使用凭证
  timeout: 1000 * 30,// 请求 30s 超时
  // validateStatus: function () {
  //   // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
  //   return true
  // },
  // 请求、响应处理
  // 在向服务器发送请求前，序列化请求数据
  // transformRequest: [function (data) {
  //   data = JSON.stringify(data)
  //   return data
  // }],
  // // 在传递给 then/catch 前，修改响应数据
  // transformResponse: [function (data) {
  //   if (typeof data === 'string' && data.startsWith('{')) {
  //     data = JSON.parse(data)
  //   }
  //   return data
  // }]
})

let loading;
//内存中正在请求的数量
let loadingNum = 0;
// 开始加载动画
let startLoading = () => {
  if (loadingNum == 0) {
    loading = Loading.service({
      lock: true,
      text: '拼命加载中...',
      background: 'rgba(255,255,255,0.5)',
    })
  }
  //请求数量加1
  loadingNum++;
}
// 结束加载动画
let endLoading = () => {
  //请求数量减1
  loadingNum--
  if (loadingNum <= 0) {
    loading.close()
  }
}

// 正在请求的url数组
let pending = [];
// 是否正在请求
let isPending = url => pending.includes(url);
// 移除完成的请求
let removePending = url => {
  let index = pending.findIndex(item => item === url);
  pending.splice(index, 1);
};


// 请求拦截器
request.interceptors.request.use(
  (config) => {
    if (!config.headers.noIntercept && isPending(config.url)) {
      return Promise.reject(new Error('重复请求已被拦截!'));
    }
    pending.push(config.url);
    startLoading()
    if (config.headers.token && store.getters.userToken) {
      config.headers.Authorization = `Bearer ${store.getters.userToken}`
      delete config.headers.token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器
request.interceptors.response.use(
  (response) => {
    removePending(response.config.url);
    endLoading()
    if (response.data.status == 4003) {
      Message.error('登录过期，请重新登录')
      store.dispatch('setUserToken', '')
      return Promise.reject(response.data || response)
    } else {
      return response.data || response
    }
  },
  (error) => {
    pending = [];
    endLoading()
    if (error.response) {
      error.message = showStatus(error.response.status)
      return Promise.reject(error.response.data || error.response || error)
    } else {
      Message.error('请求超时，请稍后再试');
    }
    // Message.error(error.message);
  }
)
const showStatus = (status) => {
  let message = ''
  // 这一坨代码可以使用策略模式进行优化
  switch (status) {
    case 400:
      message = '请求错误(400)'
      break
    case 401:
      message = '未授权，请重新登录(401)'
      break
    case 403:
      message = '拒绝访问(403)'
      break
    case 404:
      message = '请求出错(404)'
      break
    case 408:
      message = '请求超时(408)'
      break
    case 500:
      message = '服务器错误(500)'
      break
    case 501:
      message = '服务未实现(501)'
      break
    case 502:
      message = '网络错误(502)'
      break
    case 503:
      message = '服务不可用(503)'
      break
    case 504:
      message = '网络超时(504)'
      break
    case 505:
      message = 'HTTP版本不受支持(505)'
      break
    default:
      message = `连接出错(${status})!`
  }
  return `${message}，请检查网络或联系管理员！`
}
export default request