import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      hideFooter: true
    },
  },
  // 企业列表
  {
    path: '/enterprise',
    name: 'Enterprise',
    component: () => import('@/views/Enterprise/index.vue'),
    meta: {
      isSearch: true
    }
  },
  // 企业详情页
  {
    path: '/enterprise-details',
    name: 'EnterpriseDetails',
    component: () => import('@/views/Enterprise/details/index.vue'),
  },
  // 专利列表页
  {
    path: '/patent',
    name: 'Patent',
    component: () => import('@/views/Patent/index.vue'),
    meta: {
      isSearch: true
    }
  },
  // 专利详情页
  {
    path: '/patent-details',
    name: 'PatentDetails',
    component: () => import('@/views/Patent/details.vue')
  },
  // 专利复审、无效页
  {
    path: '/patent-lawInfo',
    name: 'PatentLawInfo',
    component: () => import('@/views/Patent/lawInfo.vue')
  },
  // 商标列表页
  {
    path: '/trademark',
    name: 'Trademark',
    component: () => import('@/views/Trademark/index.vue'),
    meta: {
      isSearch: true
    }
  },
  // 商标详情页
  {
    path: '/trademark-details',
    name: 'TrademarkDetails',
    component: () => import('@/views/Trademark/details.vue')
  },
  // 个人中心
  {
    path: '/personal',
    name: "Personal",
    component: () => import('@/views/Personal/index.vue'),
    redirect: '/personal/my',
    meta: {
      loginAuth: true
    },
    children: [
      // 消息中心
      {
        path: 'notice',
        name: 'Notice',
        component: () => import('@/views/Personal/notice.vue')
      },
      // 监控列表
      {
        path: 'monitorList',
        name: 'MonitorList',
        component: () => import('@/views/Personal/monitorList.vue')
      },
      // 监控日报
      {
        path: 'daily',
        name: 'Daily',
        component: () => import('@/views/Personal/daily/index.vue')
      },
      // 监控日报--详情
      {
        path: 'daily-details',
        name: 'DailyDetails',
        component: () => import('@/views/Personal/daily/details.vue'),
        meta: {
          path: '/personal/daily'
        }
      },
      // 年费监控
      {
        path: 'annual_cost',
        name: 'AnnualCost',
        component: () => import('@/views/Personal/annualCost.vue')
      },
      // 报告下载
      {
        path: 'report',
        name: 'ReportDownload',
        component: () => import('@/views/Personal/reportDownload.vue')
      },
      // 基本信息
      {
        path: 'my',
        name: "My",
        component: () => import('@/views/Personal/my.vue'),
      }
    ]
  },
  {
    path: '*',
    name: 'error',
    component: () => import('@/components/error.vue'),
    meta: {
      title: '页面没找到'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})
router.beforeEach((to, from, next) => {
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

  let isLogin = store.getters.userToken // 是否登录
  // 未登录状态；当路由到nextRoute指定页时，跳转至login
  if (to.matched.some(record => record.meta.loginAuth)) {
    if (!isLogin) {
      next({ path: from.fullPath || from.path, })
      store.dispatch('setShowLogin', true)
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
