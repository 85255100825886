/** 登录 */
<template>
  <el-dialog custom-class="border-r6" width="68%" :visible.sync="showAccount" :show-close="false"
    :modal-append-to-body='false' :before-close="handleClose" center>
    <el-image class="account-logo" :src="require('@/assets/images/logo-a.png')" fit="contain"
      style="width:126px;height:34px"> </el-image>
    <!-- 登录 -->
    <Login v-if="showLogin" ref="form" />
    <!-- 注册 -->
    <Registered v-else-if="showRegister" ref="form" />
    <!-- 找回密码 -->
    <PassBack v-else ref="form" />

    <div slot="footer" class="dialog-footer">
      <el-button v-if="showLogin" type="text" @click="setShowRegister(true)">注册账号</el-button>
      <el-button v-if="!showLogin && !userToken" type="text" @click="setShowLogin(true)">登录</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Login from './login.vue'
import Registered from './registered.vue'
import PassBack from './passBack.vue'
export default {
  name: 'Account',
  components: {
    Login,
    Registered,
    PassBack
  },
  data () {
    return {};
  },
  computed: {
    ...mapGetters([
      'userToken',
      'showAccount',
      'showLogin',
      'showRegister'
    ])
  },
  methods: {
    ...mapActions([
      'setShowAccount',
      'setShowLogin',
      'setShowRegister',
      'setPassBack'
    ]),

    // dialog关闭前的回调
    handleClose () {
      // 重置form表单
      this.$refs.form.$refs.form.resetFields()
      this.$refs.form.ifyCode = '' // 注册-图形验证码重置为空
      this.$refs.form.passwords = '' // 注册-二次密码重置为空
      this.setShowAccount(false)
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  max-width: 400px;

  .el-dialog__body {
    text-align: center;
    padding: 20px 60px 0;
  }
  .el-dialog__footer {
    padding: 0 60px 30px;
    .el-button {
      font-weight: bold;
      padding-bottom: 0;
    }
  }
}

::v-deep .el-form {
  margin-top: 30px;
  // 输入框
  .el-input {
    .el-input__icon {
      font-size: 18px;
    }
    .el-input__inner {
      flex: 1;
      padding-right: 42px;
      background-color: #eff2f7;
      border: none;
    }
    // 输入框右侧图标
    .el-input__suffix {
      line-height: 0;
      right: 0;
      cursor: pointer;
      .pass-eye {
        padding: 11px 12px;
        border: none;
        background-color: transparent;
      }
    }
    .el-input-group__append {
      margin-left: 10px;
      width: 90px;
      padding: 0;
      background-color: transparent;
      border: 1px solid #2974ef;
      border-radius: 4px;
      line-height: 38px;
      overflow: hidden;
      .el-button {
        padding-left: 10px;
        padding-right: 10px;
        color: #65b1ff;
      }
    }
    &.no-append-border .el-input-group__append {
      border-color: transparent;
    }
  }
  // 忘记密码按钮
  .back-pass {
    font-size: 14px;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    right: 0;
  }

  // 提交按钮
  .form-btns {
    &.login-btn {
      padding-top: 16px;
    }
    .el-button {
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      border-radius: 4px;
      margin-top: 5px;
      border: none;
    }
  }

  // 地区下拉选择
  .el-select {
    width: 100%;
    .el-input__inner {
      padding-left: 30px;
    }
  }
  // 所在地图标
  .el-icon-place {
    position: absolute;
    top: 1px;
    left: 5px;
    font-size: 18px;
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 40px;
    color: #c0c4cc;
  }
}
</style>