<template>
  <div class="my-footer">
    <div v-if="!$route.meta.hideFooter" class="footer-con">
      <div class="content align-center">
        <el-image :src="require('@/assets/images/logo-h.png')" fit="contain"></el-image>
        <el-row class="footer-related-url" type="flex" :gutter="34">
          <el-col :span="9">
            <a href="https://cnipol.com/Index/lists/catid/9.html" target="_blank">关于我们</a>
          </el-col>
          <el-col :span="9">
            <a href="https://cnipol.com/Index/lists/catid/12.html" target="_blank">联系我们</a>
          </el-col>
          <el-col :span="9">
            <router-link to="/personal/my">个人中心</router-link>
          </el-col>
        </el-row>
        <div class="footer-hotline flex-1">
          <p class="align-center">
            <span class="mr-22">
              <el-image :src="require('../assets/images/z_icon7.png')" fit="contain"></el-image>
              服务热线
            </span>
            <span>010-53318899</span>
          </p>

        </div>
      </div>
    </div>
    <div class="footer-beian">
      <div class="content">
        <a href="http://beian.miit.gov.cn" target="_blank">Copyright ©2017  中知在线（北京）知识产权运营有限公司 ・ 京ICP备20025087号-3</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.footer-con {
  padding: 40px 0 30px;
  .footer-related-url {
    float: left;
    align-items: center;
    margin-left: 72px !important;
    a {
      color: #fff;
    }
  }
  .footer-hotline {
    p {
      float: right;
      .el-image {
        vertical-align: bottom;
      }
      span:first-child {
        font-size: 13px;
        color: #d2d2d2;
      }
      span:last-child {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
}
.footer-beian {
  background-color: #282828;
  padding: 18px 0;
  text-align: center;
  a {
    font-size: 12px;
    color: #d2d2d2;
  }
}
</style>