<template>
  <div class="search">
    <el-input :placeholder="searchPlace" v-model="searchVal" prefix-icon="el-icon-search" clearable
      class="input-with-select" @keyup.enter.native="searchBtn">
      <el-button class="search-btn" slot="append" @click="searchBtn">搜 索</el-button>
    </el-input>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Search',
  inject: ['reload'],//注入App里的reload方法
  props: {
    isStatus: {
      typeof: Boolean,
      default: false
    },
    placeholder: {
      typeof: String
    },
    isRequired: {
      typeof: Boolean,
      default: true
    }
  },
  data () {
    return {
      oldSearchVal: '',
      searchVal: '',
      searchPlace: this.isStatus ? this.placeholder : '请输入查询企业名称',
    }
  },
  computed: {
    ...mapGetters([
      'queryType'
    ])
  },
  watch: {
    queryType () {
      this.queryTypeName()
    },
  },
  mounted () {
    this.searchVal = this.$route.query.search_val
    this.queryTypeName()
  },
  methods: {
    queryTypeName () {
      if (this.placeholder) {
        return false
      }
      switch (this.queryType) {
        case 'enterprise':
          this.searchPlace = '请输入查询企业名称'
          break;
        case 'patent':
          this.searchPlace = '请输入查询专利名称、专利号或申请人'
          break;
        case 'trademark':
          this.searchPlace = '请输入查询商标名称、注册号或申请人'
          break;
        default:
          break;
      }
    },
    searchBtn () {
      if (!this.isRequired && !this.oldSearchVal && !this.searchVal) {
        this.$message.warning('您还未输入关键词')
        return false
      }
      this.oldSearchVal = this.searchVal
      if (!this.isRequired || this.searchVal) {
        if (this.isStatus) {
          this.$emit('searchBtn', this.searchVal)
        } else {
          this.$router.push({ path: `/${this.queryType}`, query: { search_val: this.searchVal } })
          this.reload() // 登录成功刷新数据
        }
      } else {
        this.$message.warning('您还未输入关键词')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search {
  height: 40px;
}
::v-deep .el-input {
  height: 100%;
  border-radius: 4px;
  .el-input__inner {
    height: 100%;
    border: none;
  }
  .el-input-group__append {
    border: none;
    .el-button {
      height: 100%;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      background-color: #409eff;
      &:hover {
        background-color: #66b1ff;
      }
    }
  }
}
</style>