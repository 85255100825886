import request from '@/utils/request.js';
// 获取token
export const getToken = () => {
  return request({
    method: 'get',
    url: '/getToken'
  })
}

// 获取用户信息
export const getUserInfo = () => {
  return request({
    method: 'get',
    url: '/userinfo',
    headers: {
      token: true
    }
  })
}

// 修改用户信息
export const updateUserInfo = (data) => {
  return request({
    method: 'post',
    url: '/mifityuser',
    headers: {
      token: true
    },
    data
  })
}

// 提醒消息条数
export const getUserMessageNum = () => {
  return request({
    method: 'get',
    url: '/usermessage',
    headers: {
      token: true
    },
  })
}

// 登录
export const login = (data) => {
  return request({
    method: 'post',
    url: '/login',
    data
  })
}

// 登出
export const logout = () => {
  return request({
    method: 'get',
    url: '/loginout',
    headers: {
      token: true
    }
  })
}

// 发送找回密码验证码
export const passBackSendCode = (phone) => {
  return request({
    method: 'post',
    url: '/retrieve',
    data: {
      phone
    }
  })
}

// 找回密码
export const passBack = (data) => {
  return request({
    method: 'post',
    url: '/Retrievepassword',
    data
  })
}

// 发送注册验证码
export const regSendCode = (phone) => {
  return request({
    method: 'post',
    url: '/registeredcode',
    data: {
      phone
    }
  })
}

// 注册
export const register = (data) => {
  return request({
    method: 'post',
    url: '/register',
    data
  })
}