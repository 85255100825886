/** 找回密码 */
<template>
  <el-form :model="form" :rules="$rules.common" ref="form">
    <el-form-item prop="phone">
      <el-input v-model="form.phone" placeholder="请输入手机号码" prefix-icon="el-icon-mobile-phone"></el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input class="flex" v-model="form.code" placeholder="请输入验证码" prefix-icon="el-icon-message">
        <el-button class="send-code" slot="append" :disabled="isDisabled" @click="getCode('form')">{{codeMsg}}
        </el-button>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input type="password" v-model="form.password" placeholder="请输入登录密码" autocomplete="off"
        prefix-icon="el-icon-lock"></el-input>
    </el-form-item>
    <el-form-item class="form-btns">
      <el-button class="theme-back" type="primary" :loading="isLoading" @click="login('form')">
        {{$store.getters.userToken?'修改密码':'找回密码'}}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { passBackSendCode, passBack } from '@/api/account'
export default {
  name: 'passBack',
  data () {
    return {
      isLoading: false, // 登录加载状态
      isDisabled: false, // 发送验证码后禁用
      count: 0, // 验证码倒计时
      codeMsg: '获取验证码',
      form: {
        phone: '',
        code: '',
        password: '',
      },
    };
  },
  methods: {
    // 找回密码
    login (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            let { data, status, msg } = await passBack(this.form)
            if (status == 200) {
              this.$message.success('密码找回成功，请重新登录');
              this.$store.dispatch('setShowLogin', true)
            } else {
              this.$message.error(msg)
            }
          } catch (error) {
            this.$message.error(error.errors.phone[0]);
          }
          this.isLoading = false
        } else {
          return false
        }
      });
    },
    //  获取验证码
    getCode (formName) {
      this.$refs[formName].validateField("phone", async valid => {
        if (!valid) {
          this.codeMsg = '加载中'
          this.countDown(60)
          try {
            let { data, status, msg } = await passBackSendCode(this.form.phone)
            if (status == 200) {
              this.$message.success('验证码发送成功');
            } else {
              this.$message.error(msg);
              this.endTimer()
            }
          } catch (error) {
            this.$message.error(error.errors.phone[0]);
            this.endTimer()
          }
        } else {
          return false;
        }
      })
    },
    // 开始倒计时
    countDown (num) {
      if (!this.timer) {
        this.count = num
        this.codeMsg = `${num}秒`
        this.isDisabled = true
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= num) {
            this.count--
            this.codeMsg = `${this.count}秒`
          } else {
            this.endTimer()
          }
        }, 1000)
      }
    },
    // 结束倒计时
    endTimer (msg) {
      this.isDisabled = false
      clearInterval(this.timer)
      this.timer = null
      this.codeMsg = msg || '重新发送'
    }
  },
}
</script>