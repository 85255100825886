/** 注册 */
<template>
  <el-form :model="form" :rules="$rules.common" ref="form">

    <el-form-item prop="phone">
      <el-input v-model="form.phone" placeholder="请输入手机号码" prefix-icon="el-icon-mobile-phone"></el-input>
    </el-form-item>

    <el-form-item prop="ifyCode" :rules="[{ validator: validateIfyCode, trigger: 'blur' }]">
      <el-input class="flex no-append-border" v-model="ifyCode" placeholder="请输入右侧校验码"
        prefix-icon="el-icon-copy-document">
        <div slot="append" @click="refreshCode">
          <s-identify :identifyCode="identifyCode"></s-identify>
        </div>
      </el-input>
    </el-form-item>

    <el-form-item prop="code">
      <el-input class="flex" v-model="form.code" placeholder="请输入验证码" prefix-icon="el-icon-message">
        <el-button class="send-code" slot="append" :disabled="isDisabled" @click="getCode('form')">{{codeMsg}}
        </el-button>
      </el-input>
    </el-form-item>

    <el-form-item prop="password">
      <el-input type="password" v-model="form.password" placeholder="请输入登录密码" autocomplete="off"
        prefix-icon="el-icon-lock"></el-input>
    </el-form-item>

    <el-form-item prop="password_confirmation" :rules="[{ validator: validatePasswords, trigger: 'blur' }]">
      <el-input type="password" v-model="form.password_confirmation" placeholder="请确认登录密码" autocomplete="off"
        prefix-icon="el-icon-lock">
      </el-input>
    </el-form-item>

    <el-form-item prop="name">
      <el-input v-model="form.name" placeholder="请输入用户名称" prefix-icon="el-icon-user"> </el-input>
    </el-form-item>

    <el-form-item prop="province" :rules="[{ required: true, message: '请选择所在地' }]">
      <el-select v-model="form.province" filterable placeholder="请选择所在地">
        <el-option v-for="item in provs" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <i class="el-icon-place"></i>
    </el-form-item>

    <el-form-item class="form-btns">
      <el-button class="theme-back" type="primary" :loading="isLoading" @click="login('form')">注册</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import SIdentify from '@/components/sIdentify'
import { regSendCode, register } from '@/api/account'
export default {
  name: 'Registered',
  components: {
    SIdentify
  },
  data () {
    return {
      isLoading: false, // 注册加载状态
      identifyCode: '',//图形验证码生成
      identifyCodes: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],//图形验证码生成规则
      isDisabled: false, // 发送验证码后禁用
      count: 0, // 验证码倒计时
      codeMsg: '获取验证码',
      ifyCode: '',//图形验证码
      form: {
        phone: '',
        code: '',
        password: '',
        password_confirmation: '',
        name: '',
        province: ''
      },
      // 省份数据
      provs: [
        {
          label: '北京',
          value: '北京'
        },
        {
          label: '天津',
          value: '天津'
        },
        {
          label: '河北',
          value: '河北',

        },
        {
          label: '山西',
          value: '山西',

        },
        {
          label: '内蒙古',
          value: '内蒙古',

        },
        {
          label: '辽宁',
          value: '辽宁',

        },
        {
          label: '吉林',
          value: '吉林',

        },
        {
          label: '黑龙江',
          value: '黑龙江',

        },
        {
          label: '上海',
          value: '上海',

        },
        {
          label: '江苏',
          value: '江苏',

        },
        {
          label: '浙江',
          value: '浙江',
        },
        {
          label: '安徽',
          value: '安徽',
        },
        {
          label: '福建',
          value: '福建',
        },
        {
          label: '江西',
          value: '江西',
        },
        {
          label: '山东',
          value: '山东',
        },
        {
          label: '河南',
          value: '河南',
        },
        {
          label: '湖北',
          value: '湖北',
        },
        {
          label: '湖南',
          value: '湖南',
        },
        {
          label: '广东',
          value: '广东',
        },
        {
          label: '海南',
          value: '海南',
        },
        {
          label: '重庆',
          value: '重庆'
        },
        {
          label: '四川',
          value: '四川',
        },
        {
          label: '贵州',
          value: '贵州',
        },
        {
          label: '云南',
          value: '云南',
        },
        {
          label: '西藏',
          value: '西藏',
        },
        {
          label: '陕西',
          value: '陕西',
        },
        {
          label: '甘肃',
          value: '甘肃',
        },
        {
          label: '青海',
          value: '青海',
        },
        {
          label: '宁夏',
          value: '宁夏',
        },
        {
          label: '新疆',
          value: '新疆',
        },
        {
          label: '台湾',
          value: '台湾',
        },
        {
          label: '香港',
          value: '香港',
        },
        {
          label: '澳门',
          value: '澳门',
        }
      ],
      // 图形验证码校验
      validateIfyCode: (rule, value, callback) => {
        if (this.ifyCode === '') {
          callback(new Error('图形验证码不能为空'));
        } else if (this.ifyCode !== this.identifyCode) {
          callback(new Error('图形验证码输入错误!'));
        } else {
          callback();
        }
      },
      // 二次密码验证
      validatePasswords: (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      },
    };
  },
  mounted () {
    this.refreshCode()
  },
  methods: {
    // 注册
    login (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            let { data, status, msg } = await register(this.form)
            if (status == 200) {
              this.$message.success('注册成功');
              this.$store.dispatch('setUserToken', data.token)
              this.$store.dispatch('setUserInfo', data.userInfo)
            } else {
              this.$message.error(msg)
            }
          } catch (error) {
            this.$message.error(error.errors.phone[0]);
          }
          this.isLoading = false
        } else {
          return false;
        }
      });
    },
    //  获取验证码
    async getCode (formName) {
      const validateList = []   // 定义空数组
      this.$refs[formName].validateField(["phone", 'ifyCode'], valid => {
        // this.field  为要校验的部分rules数组
        validateList.push(valid)
      })

      if (validateList.every((item) => item === '')) {
        this.codeMsg = '加载中'
        this.countDown(60)
        try {
          let { status, msg } = await regSendCode(this.form.phone)
          if (status == 200) {
            this.$message.success('验证码发送成功');
          } else {
            this.$message.error(msg);
            this.endTimer()
          }
        } catch (error) {
          this.$message.error(error.errors.phone[0]);
          this.endTimer()
        }

      }
    },
    // 生成随机数
    randomNum (min, max) {
      max += 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 更新图形验证码
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 随机生成图形验证码字符串
    makeCode (data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length - 1)
        ]
      }
    },
    // 开始倒计时
    countDown (num) {
      if (!this.timer) {
        this.count = num
        this.codeMsg = `${num}秒`
        this.isDisabled = true
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= num) {
            this.count--
            this.codeMsg = `${this.count}秒`
          } else {
            this.endTimer()
          }
        }, 1000)
      }
    },
    // 结束倒计时
    endTimer (msg) {
      this.isDisabled = false
      clearInterval(this.timer)
      this.timer = null
      this.codeMsg = msg || '重新发送'
    }
  },
}
</script>