<template>
  <div class="my-header theme-back">
    <div class="content justify-between-center">
      <div class="align-center">
        <router-link to="/">
          <el-image class="logo" :src="require('@/assets/images/logo-h.png')" fit="contain">
          </el-image>
        </router-link>
        <el-row :gutter="40" type="flex" class="query-type">
          <el-col v-for="item in queryTypes" :key="item.name">
            <router-link to="/">
              <el-button :class="{'is-active':queryType===item.name}" type="text" @click="setQueryType(item.name)">
                {{item.label}}
              </el-button>
            </router-link>
          </el-col>
        </el-row>
      </div>
      <Search v-if="this.$route.meta.isSearch" />
      <!-- 未登录 -->
      <div v-if="!userToken" class="header-btns">
        <el-button type="text" @click="setShowLogin(true)">登录</el-button>
        <el-button type="text" @click="setShowRegister(true)">注册</el-button>
      </div>
      <!-- 已登录 -->
      <div v-else class="user-info">
        <router-link to="/personal/notice">
          <el-badge :value="userMessageNum" :max="99">
            <i class="el-icon-message-solid user-notice"></i>
          </el-badge>
        </router-link>
        <el-dropdown class="ml-22" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{userInfo.name || userInfo.phone}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" class="user-info-dropdown">
            <el-dropdown-item>
              <a href="javascript:;" >{{userInfo.level_name}}
                <span class="user-upgrade theme-back ml-10">{{userInfo.level_sort>=5?'查看':'升级'}}</span>
              </a>
            </el-dropdown-item>
            <el-dropdown-item command="monitorList"> 监控中心 </el-dropdown-item>
            <el-dropdown-item command="updatePass"> 修改密码 </el-dropdown-item>
            <el-dropdown-item command="logout"> 退出登录 </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <Account />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { logout, getUserMessageNum } from '@/api/account'
import Account from '@/views/Account/index.vue'
import Search from './Search.vue'
export default {
  components: {
    Account,
    Search
  },
  data () {
    return {
      userMessageNum: 0,
      queryTypes: [
        { label: '企业查询', name: 'enterprise', },
        { label: '专利查询', name: 'patent', },
        { label: '商标查询', name: 'trademark', },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'userToken',
      'userInfo',
      'queryType',
    ])
  },
  mounted () {
    this.getUserMessageNum()
  },
  methods: {
    ...mapActions([
      'setUserToken',
      'setShowLogin',
      'setShowRegister',
      'setPassBack',
      'setQueryType'
    ]),
    async getUserMessageNum () {
      if (!this.$store.getters.userToken) return false
      let { data, status } = await getUserMessageNum()
      if (status == 200) {
        this.userMessageNum = data.count
      }
    },
    async handleCommand (command) {
      switch (command) {
        case 'monitorList':
          this.$router.push('/personal/monitorList')
          break;
        case 'updatePass':
          this.setPassBack('')
          break;
        case 'logout':
          await logout()
          this.setUserToken('')
          break;

        default:
          break;
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.my-header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.query-type {
  margin-left: 20px !important;
  .el-button {
    padding: 8px 0;
    border-bottom: 2px solid transparent;
    &.el-button--text {
      color: #d6d9dd;
      &:hover {
        color: #fff;
      }
    }
    &.el-button--text.is-active {
      color: #fff;
      border-color: transparent;
      border-bottom: 2px solid #fff;
    }
  }
}

.header-btns {
  .el-button--text {
    color: #fff;
  }
}
::v-deep .user-info {
  .el-badge__content {
    height: 14px;
    line-height: 14px;
    padding: 0px 4px;
  }
  .el-dropdown {
    color: #fff;
    font-size: 16px;
  }
}
.user-notice {
  color: #fff;
}
.user-upgrade {
  padding: 3px 10px;
  border-radius: 12px;
}
</style>