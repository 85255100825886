import Vue from 'vue'
import Vuex from 'vuex'
import { Message } from 'element-ui'
import { setItem, getItem } from '@/utils/storage'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userToken: getItem('USER_TOKEN') || '',
    userInfo: getItem('USER_INFO') || null,
    showAccount: false,// 登录注册框
    showLogin: false,// 登录表单
    showRegister: false,// 注册表单
    queryType: getItem('QUERY_TYPE', 'sessionStorage') || 'enterprise',// 首页查询类型(企业、专利、商标)
    downloadDialog: false,//下载框是否展示
  },
  mutations: {
    SET_USER_TOKEN (state, value) {
      state.userToken = value
      setItem('USER_TOKEN', value)
      state.showAccount = false
    },
    SET_USER_INFO (state, value) {
      state.userInfo = value
      setItem('USER_INFO', value)
    },
    SET_SHOW_ACCOUNT (state, value) {
      state.showAccount = value
    },
    SET_SHOW_LOGIN (state, value) {
      state.showAccount = true
      state.showLogin = value
      state.showRegister = false
    },
    SET_SHOW_REGISTER (state, value) {
      state.showAccount = true
      state.showLogin = false
      state.showRegister = value
    },
    SET_BACK_PASS (state, value) {
      state.showAccount = true
      state.showLogin = false
      state.showRegister = false
    },
    SET_QUERY_TYPE (state, value) {
      state.queryType = value
      setItem('QUERY_TYPE', value, 'sessionStorage')
    },
    SET_DOWNLOAD_DIALOG (state, value) {
      if (value && !state.userToken) return Message.warning('请先登录')
      state.downloadDialog = value
    },
  },
  actions: {
    // 存储用户token
    setUserToken (context, val) {
      context.commit('SET_USER_TOKEN', val)
    },
    // 存储用户手机号、会员等级
    setUserInfo (context, val) {
      context.commit('SET_USER_INFO', val)
    },
    // 注册/登录框
    setShowAccount (context, val) {
      context.commit('SET_SHOW_ACCOUNT', val)
    },
    // 登录表单
    setShowLogin (context, val) {
      context.commit('SET_SHOW_LOGIN', val)
    },
    // 注册表单
    setShowRegister (context, val) {
      context.commit('SET_SHOW_REGISTER', val)
    },
    // 找回密码表单
    setPassBack (context, val) {
      context.commit('SET_BACK_PASS', val)
    },
    setQueryType (context, val) {
      context.commit('SET_QUERY_TYPE', val)
    },
    setDownloadDialog (context, val) {
      context.commit('SET_DOWNLOAD_DIALOG', val)
    }
  },
  getters: {
    userToken: state => state.userToken,
    userInfo: state => state.userInfo,
    showAccount: state => state.showAccount,
    showLogin: state => state.showLogin,
    showRegister: state => state.showRegister,
    queryType: state => state.queryType,
    downloadDialog: state => state.downloadDialog,
  },
  modules: {
  }
})
