<template>
  <div id="app">
    <Layout />
  </div>
</template>
<script>
import Layout from './views/Layout/index.vue'
export default {
  components: {
    Layout
  }
}
</script>
