<template>
  <div class="home back-fff">
    <section class="content home-title">
      <strong>数据赋能知识产权金融</strong>
      <p>知识产权数据查询、金融风险动态监控、分析报告一键获取</p>
    </section>
    <section class="content home-search mb-20">
      <ul class="query-type flex">
        <li v-for="item in queryTypes" :key="item.name">
          <el-button :class="{'is-active':queryType===item.name}" type="text" @click="setQueryType(item.name)">
            {{item.label}}
          </el-button>
        </li>
      </ul>
      <Search />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Search from '@/components/Search.vue'
export default {
  name: 'Home',
  components: {
    Search
  },
  data () {
    return {

      queryTypes: [
        { label: '企业查询', name: 'enterprise', },
        { label: '专利查询', name: 'patent', },
        { label: '商标查询', name: 'trademark', },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'queryType'
    ])
  },
  methods: {
    ...mapActions([
      'setQueryType'
    ]),
  },
}
</script>
<style lang="scss" scoped>
.home {
  position: absolute;
  width: 100%;
  min-height: 100% !important;
  background-image: url("../../assets/images/home-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.home-title {
  padding: 110px 0;
  text-align: center;
  color: #cfe1ff;
  strong {
    font-size: 60px;
    font-style: italic;
  }
  p {
    font-size: 24px;
    margin-top: 30px;
  }
}
.home-search {
  max-width: 700px;
  .query-type {
    li {
      margin-right: 48px;
    }
    .el-button {
      font-size: 18px;
      color: #c0d7fc;
      &:hover {
        color: #fff;
      }
      &.el-button--text.is-active {
        color: #fff;
        font-weight: bold;
        border-color: transparent;
      }
    }
  }
  ::v-deep .search {
    height: 46px;
  }
}
</style>
