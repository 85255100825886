import {
  Message,
  MessageBox,
  Button,
  Container,
  Header,
  Footer,
  Main,
  Aside,
  Image,
  Row,
  Col,
  Dialog,
  Form,
  FormItem,
  Input,
  Tag,
  Table,
  TableColumn,
  Checkbox,
  CheckboxGroup,
  Pagination,
  Tabs,
  TabPane,
  Descriptions,
  DescriptionsItem,
  DatePicker,
  Select,
  Option,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  BreadcrumbItem,
  Menu,
  MenuItem,
  Avatar,
  Upload,
  Badge,
  Collapse,
  CollapseItem,
  Empty,
  Result,
  Switch,
  Popover,
} from 'element-ui'
export default {
  install (Vue) {
    Vue.use(Button)
    Vue.use(Container)
    Vue.use(Header)
    Vue.use(Footer)
    Vue.use(Main)
    Vue.use(Aside)
    Vue.use(Image)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Dialog)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Input)
    Vue.use(Tag)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Checkbox)
    Vue.use(Pagination)
    Vue.use(CheckboxGroup)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Descriptions)
    Vue.use(DescriptionsItem)
    Vue.use(DatePicker)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Dropdown)
    Vue.use(DropdownMenu)
    Vue.use(DropdownItem)
    Vue.use(Breadcrumb)
    Vue.use(BreadcrumbItem)
    Vue.use(Menu)
    Vue.use(MenuItem)
    Vue.use(Avatar)
    Vue.use(Upload)
    Vue.use(Badge)
    Vue.use(Collapse)
    Vue.use(CollapseItem)
    Vue.use(Empty)
    Vue.use(Result)
    Vue.use(Switch)
    Vue.use(Popover)
    //注意，如果使用了Message组件，并使用Vue.use(Message)的话。就掉坑里了。每次刷新页面都会弹出一个Message组件
    //Vue.use(Message)

    //直接使用this.$message
    Vue.prototype.$message = Message
    Vue.prototype.$MessageBox = MessageBox
  }
}