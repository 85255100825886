// 通过元素名删除数组中该元素
export const arrRemove = Array.prototype.remove = function (val) {
  var index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
}
// 根据数组对象的某个字段进行排序
export const compare = (i, sort) => {
  return (a, b) => {
    if (sort) {
      return a[i] - b[i] //  a[i] - b[i]为正序，倒叙为  b[i] - a[i] 
    } else {
      return b[i] - a[i]
    }
  }
}

// 根据数组对象的某个字段去重
export const unique = (arr, val) => {
  const res = new Map();
  return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
}

// 递归查找城市所属
export const recursiveCityFind = (data,city, keyword, arr = []) => {

  data.find((item) => {
    if (item.name == city && item.level=='city') {
     let district = item.districts.find(i=>i.name==keyword) || {}
     if (Object.keys(district).length) {
      arr.push(item.name)
      return true;
     }
    } else if (item.districts && item.districts.length) {
      recursiveCityFind(item.districts,city, keyword, arr);
      if (arr.length) {
        arr.push(item.name);
        return true;
      } else {
        return false;
      }
    }
    return false;
  });
  return arr[1];
}