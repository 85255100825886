<template>
  <el-container direction="vertical" class="layout-container">
    <!-- 头部 -->
    <el-header height="80px">
      <Header />
    </el-header>
    <!-- 主体 -->
    <el-main>
      <router-view v-if="isRouterAlive" />
    </el-main>
    <!-- 尾部 -->
    <el-footer :class="{'hide-footer':$route.meta.hideFooter}" height="auto">
      <Footer />
    </el-footer>
  </el-container>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { getToken } from '@/api/account'
export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
  },
  provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true // 控制视图是否显示的变量
    }
  },
  mounted () {
    if (!this.$store.getters.userToken) {
      this.getToken()
    }
  },
  methods: {
    async getToken () {
      try {
        let { data, status } = await getToken()
        if (status == 200) {
          this.$store.dispatch('setUserToken', data.token)
          this.$store.dispatch('setUserInfo', data.userInfo)
        }
      } catch (error) {
        return false
      }
    },
    reload () {
      this.isRouterAlive = false; // 先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; // 再打开
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.layout-container {
  height: 100%;

  .el-header {
    padding: 0;
  }
  .el-main {
    overflow: initial;
    background-color: #f4f5f9;
    position: relative;
    & > div {
      min-height: calc(100% - 156px);
    }
  }
  ::v-deep .el-footer {
    padding: 0;
    background-color: #323232;
    color: #fff;
    z-index: 11;
    &.hide-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: transparent;
      .footer-beian {
        background-color: transparent;
      }
    }
  }
}
</style>