import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './router'
import store from './store'
import axios from 'axios';
import elementui from './plugins/elementUi'
import infiniteScroll from "vue-infinite-scroll";
import echarts from './plugins/echartsUi'
import rules from './utils/formCheck.js'
import * as utils from "./utils";

import './assets/css/reset.scss'
import './assets/css/common.scss'
import './assets/css/elCommon.scss'

Vue.config.productionTip = false
Vue.use(elementui)
Vue.use(infiniteScroll);
Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts
Vue.prototype.$rules = rules
Vue.prototype.$utils = utils;

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)

// 时间戳转化
import * as moment from '@/utils/filter'

// 解决路由两次重定向问题
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.prototype.$request_base = process.env.VUE_APP_REPORT_URL // 文件下载前缀
Vue.prototype.$img_base = process.env.VUE_APP_IMAGE_URL // 图片地址


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
